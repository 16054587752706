import React, { createContext, useState, useEffect } from "react"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    // Инициализация токена из localStorage
    return typeof window !== "undefined" ? localStorage.getItem("token") : null
  })

  const saveToken = newToken => {
    setToken(newToken)
    if (typeof window !== "undefined") {
      localStorage.setItem("token", newToken)
    }
  }

  const clearToken = () => {
    setToken(null)
    if (typeof window !== "undefined") {
      localStorage.removeItem("token")
    }
  }

  return (
    <AuthContext.Provider value={{ token, saveToken, clearToken }}>
      {children}
    </AuthContext.Provider>
  )
}
